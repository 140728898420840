import React from 'react'

import { Wrapper, SEO } from '@renderbus/common/components'
import {
  ContentContainer,
  TitleImg,
  RulesTitleImg,
  PrizeList,
  PrizeItem,
  PrizeImgWrap,
  RulesList,
  DescriptionList,
  QrcodeList,
  QrcodeWrap,
  StepImgWrap,
} from './ysdh-year-end-recharge.atom'
import { YearEndRecharge2021Banner } from '../molecules/banner'

import PrizeImage1 from '../images/ysdh-mid-year-recharge/prize/prize-1.png'
import PrizeImage2 from '../images/ysdh-mid-year-recharge/prize/year-end-prize-2.png'
import PrizeImage3 from '../images/ysdh-mid-year-recharge/prize/year-end-prize-3.png'
import PrizeImage4 from '../images/ysdh-mid-year-recharge/prize/year-end-prize-4.png'
import PrizeImage5 from '../images/ysdh-mid-year-recharge/prize/prize-5.png'
import PrizeImage6 from '../images/ysdh-mid-year-recharge/prize/prize-6.png'
import PrizeImage7 from '../images/ysdh-mid-year-recharge/prize/prize-7.png'
import PrizeImage8 from '../images/ysdh-mid-year-recharge/prize/prize-8.png'
import PrizeDisplayTitle from '../images/year-end-recharge/prize-display-title.png'
import ParticipationWayTitle from '../images/year-end-recharge/participation-way-title.png'
import RulesTitle from '../images/year-end-recharge/rules-title.png'
import QrcodeBilibili from '../images/year-end-recharge/qrcode-bilibili.png'
import QrcodeWechat from '../images/year-end-recharge/qrcode-wechat.png'
import QrcodeWechatHelper from '../images/year-end-recharge/year-end-recharge-qr-code.png'
import stepPC from '../images/year-end-recharge/year-end-recharge-step-2021.png'
import stepMobile from '../images/year-end-recharge/year-end-recharge-step-2021-mobile.png'
import Layout from '../molecules/layout'

const prizeImgList = [
  {
    name: '英伟达RTX4000显卡 X1',
    src: PrizeImage1,
  },
  {
    name: 'iPhone 13 （128GB）X1',
    src: PrizeImage2,
  },
  {
    name: 'Bose降噪运动耳机 X1',
    src: PrizeImage3,
  },
  {
    name: 'SKG颈椎按摩仪G7 Pro X1',
    src: PrizeImage4,
  },
  {
    name: '200元京东卡 X10',
    src: PrizeImage5,
  },
  {
    name: 'Renderbus定制小风扇 X20',
    src: PrizeImage6,
  },
  {
    name: 'Renderbus定制鼠标垫 X30',
    src: PrizeImage7,
  },
  {
    name: 'Renderbus定制数据线，参与即得',
    src: PrizeImage8,
  },
]
class MidYearRecharge2021 extends React.PureComponent {
  render() {
    const {
      location,
      pageContext: { topThreeShare },
    } = this.props
    return (
      <Layout location={location} topThreeShare={topThreeShare}>
        <SEO
          title='瑞云渲染影视动画年终渲染充值送豪礼活动 - 瑞云渲染'
          keywords='影视动画充值活动,影视动画抽奖活动,瑞云渲染充值活动'
          description='Renderbus瑞云渲染影视动画年终渲染充值送豪礼,RTX专业显卡送给你,单笔充值满5000元,即可获得1张抽奖券,登录后抽奖券可在渲染平台中点击活动浮标查看.英伟达RTX4000显卡,iPhone 13,Bose降噪运动耳机等众多大奖，等你来拿！'
        />
        <YearEndRecharge2021Banner />
        <div
          style={{
            background: '#0F1319',
          }}
        >
          <Wrapper>
            <ContentContainer>
              <TitleImg src={PrizeDisplayTitle} alt='奖品展示' />
              <PrizeList>
                {prizeImgList.map((item, index) => {
                  return (
                    <PrizeItem key={index}>
                      <PrizeImgWrap>
                        <img src={item.src} alt={item.name} />
                      </PrizeImgWrap>
                      <p>{item.name}</p>
                    </PrizeItem>
                  )
                })}
              </PrizeList>
            </ContentContainer>
            <ContentContainer>
              <TitleImg src={ParticipationWayTitle} alt='参与方式' />
              <StepImgWrap>
                <img src={stepPC} alt='' />
                <img src={stepMobile} alt='' />
              </StepImgWrap>
            </ContentContainer>
            <ContentContainer>
              <RulesTitleImg src={RulesTitle} alt='活动规则' />
              <RulesList>
                <p>
                  <span>1</span>
                  活动期间，单笔充值每满5000元，即可获得1张抽奖券，多充多送
                </p>
                <p>
                  <span>2</span>
                  充值后填写问卷可额外获得1张抽奖券
                </p>
                <p>
                  <span>3</span>12月30日统一在B站（
                  <a
                    href='https://space.bilibili.com/408650439/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    @Renderbus瑞云渲染
                  </a>
                  ）直播开奖
                </p>
                <p>
                  <span>4</span>
                  中奖后微信添加瑞云小助手（微信号：rayvision1）凭抽奖券码领奖，兑奖时间截止至2022年1月7日24点
                </p>
              </RulesList>
              <DescriptionList>
                <p>活动说明：</p>
                <p>1. 本次活动仅限影视动画渲染用户参与（青云平台用户不参与）</p>
                <p>2. 每个用户通过充值最多可获得5张抽奖券</p>
                <p>3. 登录后在充值页面点击右下角的活动浮标，可查看已获取的抽奖券</p>
                <p>4. 本活动最终解释权归瑞云科技所有</p>
              </DescriptionList>
              <QrcodeList>
                <QrcodeWrap>
                  <img src={QrcodeWechat} alt='瑞云渲染服务号' />
                  <span>瑞云渲染服务号</span>
                </QrcodeWrap>
                <QrcodeWrap>
                  <img src={QrcodeBilibili} alt='B站账号：Renderbus瑞云渲染' />
                  <span>
                    B站账号：
                    <br />
                    Renderbus瑞云渲染
                  </span>
                </QrcodeWrap>
                <QrcodeWrap>
                  <img src={QrcodeWechatHelper} alt='瑞云小助手' />
                  <span>瑞云小助手</span>
                </QrcodeWrap>
              </QrcodeList>
            </ContentContainer>
          </Wrapper>
        </div>
      </Layout>
    )
  }
}

export default MidYearRecharge2021
